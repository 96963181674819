import { navigate } from "gatsby"

const Produktfaktablad = () => {
  if (typeof window === "undefined") {
    return null
  }

  navigate("/villkor")
  return null
}

export default Produktfaktablad
